import axios      from "axios";

export const genericRequest = async (url, type, body, authorization) => {

    const headers = { Authorization: authorization }

    try {
        // Attempt to login using the code.
        let response = null;

        if(type.toLowerCase() === "get"){
            response = await axios.get(url, { data: body,  headers: headers })
        }

        // Handle POST requests.
        if(type.toLowerCase() === "post"){
            response = await axios.post(url, body, { headers: headers });
        }

        // Handle PATCH requests.
        if(type.toLowerCase() === "patch"){
            response = await axios.patch(url, body, { headers: headers });
        }

        // Handle PATCH requests.
        if(type.toLowerCase() === "put"){
            response = await axios.put(url, body, { headers: headers });
        }

        // Handle DELETE requests.
        if(type.toLowerCase() === "delete"){
            response = await axios.delete(url, { data: body,  headers: headers })
        }

        // If we didn't have the correct setup.
        if(response === null){
            return { "Success": false, "Result": "response_null" };
        }

        return response.data;
    }catch(e){
        console.log("Error", e)
    }

    return {
        "success": false,
        "result": "try_catch"
    };
}
