import React       from 'react';
import { connect } from "react-redux";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { GiSamuraiHelmet } from 'react-icons/gi';

const Header = (props) => {

  let clickableButton = (
    <div className="hc-buttons">
        <Button size="small" variant="text" color="error" onClick={props.logout}>
            Logout
        </Button>
        <Link to="/dashboard">
            <Button size="small" variant="outlined">
                Dashboard
            </Button>
        </Link>
    </div>
  );

  if(props.token === ""){
    clickableButton = (
      <Link to="#">
                <Button size="small" variant="outlined" onClick={props.login} disabled={props.loading}>
                    Login
                </Button>
      </Link>
    )
  }

  return (
    <div className="header-container">
        <div className="content">
            <div className="left">
                <Link to="/">
                    <GiSamuraiHelmet />
                </Link>
            </div>
            <div className="right">
                <Stack className="controls" spacing={2} direction="row">
                    {clickableButton}
                </Stack>
            </div>
        </div>
    </div>
  );
};


const myStateToProps = (state) => {
    return {
        token: state.loginReducer.token,
        wallet: state.loginReducer.wallet,
        loading: state.loginReducer.loading,
    };
};

export default connect(myStateToProps, { })(Header);