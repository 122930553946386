import { genericRequest } from "../generic";
import { urlHomepageStats } from "../config";

/**
 *  # apiApplicationsGet
 *  xxxx
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiHomepageGetStats = async () => {
    return genericRequest(urlHomepageStats, "get", {}, "");
};
