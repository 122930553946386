// # application
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "loaded":  false,
    "id":      "",
    "used":    0,
    "credits": 0,
};

/**
 * # apiKeysReducer
 */
const accountReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_ACCOUNT:
            state.loaded = true;
            
            state.id = payload.id;
            state.used = payload.used;
            state.credits = payload.credits;

            return {
                ...state
            };


        default:
            return state;
    };
};

export default accountReducer;