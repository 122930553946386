import { genericRequest } from "../generic";
import { apiLoginUrl, apiTestLoginUrl, apiCreateAccountUrl, apiLoadAccountUrl } from "../config";


export const apiLogin = async (sign, refresh) => {
    const param = {
        "sign": sign,
        "refresh": refresh
    }

    return genericRequest(apiLoginUrl, "post", param, "");
};

export const apiValidateLogin = async (token) => {
    return genericRequest(apiTestLoginUrl, "post", {}, token);
};

export const apiCreateAccount = async (token) => {
    return genericRequest(apiCreateAccountUrl, "post", {}, token);
};

export const apiLoadAccount = async (token) => {
    return genericRequest(apiLoadAccountUrl, "get", {}, token);
};
