import React from 'react';

import { ImTwitter, ImMail4 } from "react-icons/im"; // ImInstagram
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiSamuraiHelmet } from 'react-icons/gi';

// import Logo from '../../assets/logo.svg'; // <img src={Logo} alt="" />
import Divider from '@mui/material/Divider';

export default class Footer extends React.Component {

  state = {};

  render() {
    return (
      <div className="footer-container">
        <Divider />
        <div className="content">
          <div className="left">
            <div className="title">
              <Link to="/">
                <GiSamuraiHelmet />
              </Link>
            </div>
            <div className="copyright">
              © 2021-{new Date().getFullYear()} Digital Samurai. All rights reserved
            </div>
          </div>
          <div className="right">
            <div className="top">
              <div className="media">
                <a href="https://discord.gg/2CsfDJksd9">
                  <FaDiscord />
                </a>
                <a href="https://www.digitalsamurai.io">
                  <ImTwitter />
                </a>
                <a href="mailto:support@digitalsamurai.io">
                  <ImMail4 />
                </a>
              </div>
            </div>
            <div className="bottom">
              <div className="item">
                <Link to="/terms">Terms of Service</Link>
              </div>
              <div className="item">
                <Link to="/privacy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
