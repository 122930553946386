import React       from 'react';
import { connect } from "react-redux";

import SvgIcon from '@mui/material/SvgIcon';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HttpsIcon from '@mui/icons-material/Https';
import CloudIcon from '@mui/icons-material/Cloud';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import MessageIcon from '@mui/icons-material/Message';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}


const list = [
  {
    icon: <AttachMoneyIcon color="primary" />,
    title: "Low Cost",
    full: "We pride ourselves on being a very low cost service. We don't take a percentage but instead a low flat fee",
  },
  {
    icon: <HttpsIcon color="primary" />,
    title: "Safe and Secure",
    full: "Our service does not require special access to your smart contracts such as private key",
  },
  {
    icon: <CloudIcon color="primary" />,
    title: "Reliable",
    full: "We are hosted on beefy cloud servers that will handle the bursts of usage that often happen",
  },
  {
    icon: <PaymentIcon color="primary" />,
    title: "Only Pay What You Use",
    full: "We are a metered service because we believe you should just pay for what your project uses",
  },
  {
    icon: <CheckCircleIcon color="primary" />,
    title: "Only Pay For Success",
    full: "We only charge you for customers that successfully solve the bot deterrent",
  },
  {
    icon: <SyncDisabledIcon color="primary" />,
    title: "Easily Removable",
    full: "Decide you don't need us? You can enable / disable the protection at any time in your smart contract",
  },
  {
    icon: <DoNotDisturbIcon color="primary" />,
    title: "No NFT Pass Required",
    full: (<div>We <b>will not</b> release a NFT pass of any kind to give any solvers an advantage or priority</div>),
  },
  {
    icon: <MessageIcon color="primary" />,
    title: "Community Focused",
    full: "We love feedback! Join our Discord and talk to us about what you need so we can add it",
  }
];

class Feature extends React.Component {

  state = {};

  generateCard = (arg) => {
    return (
      <div className="item">
        <Box
          sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: 280,
                  height: 150,
                },
              }}
          >
                    <Paper>
                      <div className="hp-feature-item-internal">
                        <div className="eye-skim">
                          <div className="icon">
                            {arg.icon}
                          </div>
                          <div className="title">
                          {arg.title}
                          </div>
                        </div>
                        <div className="full">
                          {arg.full}
                        </div>
                      </div>
                    </Paper>
                  </Box>
                  </div>
    )
  }

  render() {

    const cards = list.map(this.generateCard);

    return (
        <div className="hp-feature-container">
            <div className="content">
                <div className="header">
                    Features
                </div>
                <div className="middle">
                  {cards}
                </div>
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
  };
};

export default connect(myStateToProps, { })(Feature);