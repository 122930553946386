// # profile
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "loading": false,
    "loginChecked": false, // If the login was checked.
    "noAccount": false,

    "token": (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null) ? localStorage.getItem("token") : "",
    "refresh": (localStorage.getItem("refresh") !== undefined && localStorage.getItem("refresh") !== null) ? localStorage.getItem("refresh") : "",
    "wallet": (localStorage.getItem("wallet") !== undefined && localStorage.getItem("wallet") !== null) ? localStorage.getItem("wallet") : "",
    "age": (localStorage.getItem("age") !== undefined && localStorage.getItem("age") !== null) ? localStorage.getItem("age") : "",
};

/**
 * # loginReducer
 */
const loginReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_LOGIN:

            let tmp = state.noAccount;

            // Update our state.
            state = payload;

            // Set it as checked.
            state.loginChecked = true;

            // Set the localstorage for right now.
            localStorage.setItem("token",   payload.token);
            localStorage.setItem("refresh", payload.refresh);
            localStorage.setItem("wallet",  payload.wallet);
            localStorage.setItem("age",     payload.age);

            state.noAccount = tmp;

            return {
                ...state
            };

        case types.SET_LOGIN_NO_ACCOUNT:
            state.noAccount = payload;

            console.log("SET_LOGIN_NO_ACCOUNT", payload)

            return {
                ...state
            }

        case types.SET_LOGIN_CHECKED:
            state.loginChecked = payload;

            return {
                ...state
            }

        default:
            return state;
    };
};

export default loginReducer;