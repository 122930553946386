import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';

import { Redirect } from "react-router-dom";

// Api requests.
import { apiCreateAccount } from '../../api/login';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CreateAccount(props) {

  const [agreed, setAgreed] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [redirectPlease, setRedirectPlease] = React.useState(false);

  // Logout and reload the page.
  const handleClose = () => {
    props.handleLogout();

    setTimeout(()=>{
      window.location = "/";
    }, 100);
  };

  const handleCreate = () => {

    // Set it to be loading.
    setLoading(true)

    // Create their account.
    apiCreateAccount(props.token).then((arg)=>{

      if(arg.success === false){
        window.location = "/";
        return;
      }

      // Clear the status.

      // Set that we are logged in correctly.
      // window.location = "/dashboard";
      setRedirectPlease(true);

      // Reset the form.
      setTimeout(()=>{ props.success() }, 1);
    });
  }

  if(redirectPlease){
    return (<Redirect to={{ pathname: `/dashboard` }} />)
  }

  return (
    <div>
      <Modal
        open={props.noAccount}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Account
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            To create a new account you agree to our <a className="popup-ca-link" href="/terms" alt="">terms of service</a> and <a className="popup-ca-link" href="/privacy" alt="">privacy policy</a>
          </Typography>
          <Typography id="modal-modal-description" label="Label" sx={{ mt: 2 }}>
            I agree to terms of service and privacy policy
            <Checkbox label="Label" checked={agreed} onChange={()=>{ setAgreed(!agreed); }}/>
          </Typography>
          <LoadingButton variant="contained" disabled={!agreed} loading={loading} onClick={handleCreate}>Create Account</LoadingButton>
        </Box>
      </Modal>
    </div>
  );
}