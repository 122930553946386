// # types
// this file is used to set the redux actions

const types = {
    "EXAMPLE":                 "EXAMPLE",
    "SET_LOGIN":                "SET_LOGIN",
    "SET_LOGIN_CHECKED":        "SET_LOGIN_CHECKED",
    "SET_LOADING":              "SET_LOADING",
 
    /* Applications */
    "SET_APPLICATIONS":         "SET_APPLICATIONS",
    "SET_APPLICATIONS_ENABLED": "SET_APPLICATIONS_ENABLED",
    
    /* Questions. */
    "SET_QUESTIONS":            "SET_QUESTIONS",

    /* ApiKeys */
    "SET_APIKEYS":              "SET_APIKEYS",

    /* Account */
    "SET_ACCOUNT":              "SET_ACCOUNT",

    /* Charts */
    "SET_CHART":                "SET_CHART",

    "SET_LOGIN_NO_ACCOUNT": "SET_LOGIN_NO_ACCOUNT",
};

export default types;