
// The base url.
export const baseUrl = "http://127.0.0.1";

/*
    General related urls.
*/
export const urlHomepageStats = `${baseUrl}/api/common/homepage`;

/*
    Login related urls.
*/
export const apiLoginUrl = `${baseUrl}/api/login`;
export const apiTestLoginUrl = `${baseUrl}/api/test-login`;
export const apiCreateAccountUrl = `${baseUrl}/api/create-account`;
export const apiLoadAccountUrl = `${baseUrl}/api/account`;

/*
    Application related urls.
*/
export const urlApplication = `${baseUrl}/api/application`;
export const urlApplicationEnabled = `${baseUrl}/api/application/enabled`;
export const urlCombinedReload = `${baseUrl}/api/combined`;


/*
    Api Keys related urls.
*/
export const urlApiKeys = `${baseUrl}/api/apiKey`;
export const urlApiKeysRegen = `${baseUrl}/api/regen/apikey`;

/*
    App related urls.
*/
export const urlAppCreateSimpleRecap = `${baseUrl}/api/app/simple-recaptcha`;
export const urlAppCreateSimpleHCap = `${baseUrl}/api/app/simple-hcaptcha`;

/*
    Chart related urls.
*/
export const urlLoadCharts = `${baseUrl}/api/charts`;
