// # Applications
// handles the notification actions for the redux store
// import dependencies
import types from "../constants/types";

export const setApplications = payload => ({
    "type": types.SET_APPLICATIONS,
    payload
});

export const setApplicationsEnabled = payload => ({
    "type": types.SET_APPLICATIONS_ENABLED,
    payload
});
