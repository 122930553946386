import { genericRequest } from "../generic";
import { urlApiKeys, urlApiKeysRegen } from "../config";

/**
 *  # apiApiKeysGet
 *  xxxx
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiApiKeysGet = async (authorization) => {
    return genericRequest(urlApiKeys, "get", {}, authorization);
};

export const apiApiKeysRegen = async (applicationId, authorization) => {
    return genericRequest(urlApiKeysRegen, "post", { "id": applicationId }, authorization);
};
