import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading(props) {

  const { text } = props;

  return (
    <div className="loading-container">
        <div className="center">
            <CircularProgress color="inherit" size={60} thickness={5} />
            <div className="text">
                {text}
            </div>
        </div>
    </div>
  );
}