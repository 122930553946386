// # application
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "lastChange": 0,
    "loaded": false,
    "list": [],
};

/**
 * # questionsReducer
 */
const questionsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_QUESTIONS:
            state.loaded = true;
            state.list = payload;

            return {
                ...state
            };

        default:
            return state;
    };
};

export default questionsReducer;