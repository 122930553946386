import React from 'react';

export default class BetaBanner extends React.Component {

    state = {};

    // https://emoji.gg/assets/emoji/8276_Cool_Finger_Guns.png

    render() {
        return (
            <div className="beta-banner">
                <div className="positional-child">
                    <div className="child">
                        <div className="icon">
                            <img src="https://emoji.gg/assets/emoji/8276_Cool_Finger_Guns.png" />
                        </div>
                        <div className="text">
                            We are currently in <b>Beta</b>, please provide feedback in our <a href="https://discord.gg/2CsfDJksd9">Discord</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};