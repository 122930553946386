import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditApplication(props) {


  const { open, handleClose, handleUpdateApplication, editAppId, editAppName, editAppPrivate, editAppPublic, editAppType } = props;

  // The state of the form.
  const [typeOfApplication, setTypeOfApplication]         = React.useState(editAppType);
  const [appName, setAppName]                             = React.useState(editAppName);
  const [appNameError, setAppNameError]                   = React.useState(false);
  const [recaptchaPublic, setRecaptchaPublic]             = React.useState(editAppPublic);
  const [recaptchaPublicError, setRecaptchaPublicError]   = React.useState(false);
  const [recaptchaPrivate, setRecaptchaPrivate]           = React.useState(editAppPrivate);
  const [recaptchaPrivateError, setRecaptchaPrivateError] = React.useState(false);

  if(open === false && recaptchaPublic !== ""){
    setAppName("");
    setRecaptchaPublic("");
    setRecaptchaPrivate("");
  }

  
  let apiKeyText = (
    <Typography id="modal-modal-description" sx={{ mt: 2 }} >
        You can get Recaptcha api key from <a href="https://www.google.com/recaptcha/about/"> here</a>
    </Typography>
  )

  let formMarkup = (
    <><Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <b>Google Recaptcha Public Key</b>
    </Typography><TextField
        id="outlined-basic"
        label="Public"
        value={recaptchaPublic}
        onChange={(e) => { setRecaptchaPublic(e.target.value); } }
        variant="standard"
        error={recaptchaPublicError}
        helperText={(recaptchaPublicError) ? "Public key is required" : null} /><div className="popup-spacer-x2" /><Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <b>Google Recaptcha Private Key</b>
      </Typography><TextField
        id="outlined-basic"
        label="Private"
        value={recaptchaPrivate}
        onChange={(e) => { setRecaptchaPrivate(e.target.value); } }
        variant="standard"
        error={recaptchaPrivateError}
        helperText={(recaptchaPrivateError) ? "Private Key Required" : null} /></>
  )

  if(typeOfApplication === "simple_hcaptcha"){
    apiKeyText = (
      <Typography id="modal-modal-description" sx={{ mt: 2 }} >
          You can get HCaptcha api key from <a href="https://www.hcaptcha.com/"> here</a>
      </Typography>
    );

    formMarkup = (
      <><Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <b>Cloudflare HCaptcha Public Key</b>
      </Typography><TextField
          id="outlined-basic"
          label="Public"
          value={recaptchaPublic}
          onChange={(e) => { setRecaptchaPublic(e.target.value); } }
          variant="standard"
          error={recaptchaPublicError}
          helperText={(recaptchaPublicError) ? "Public key is required" : null} /><div className="popup-spacer-x2" /><Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <b>Cloudflare HCaptcha Secret Key</b>
        </Typography><TextField
          id="outlined-basic"
          label="Secret"
          value={recaptchaPrivate}
          onChange={(e) => { setRecaptchaPrivate(e.target.value); } }
          variant="standard"
          error={recaptchaPrivateError}
          helperText={(recaptchaPrivateError) ? "Secret Key Required" : null} /></>
    );
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Application
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Change the settings on the application 
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeOfApplication}
                label="Application Type"
                onChange={setTypeOfApplication}
                disabled={true}
            >
                <MenuItem value="simple_recaptcha">Simple Recaptcha Protection</MenuItem>
                <MenuItem value="simple_hcaptcha">Simple HCaptcha Protection</MenuItem>
            </Select>
            <div className="popup-spacer-x1" />
            {apiKeyText}
            <Typography id="modal-modal-description" sx={{ mt: 2 }} >
                <b>Application Name</b>
            </Typography>
            <TextField
              id="outlined-basic"
              label="App Name"
              value={appName}
              onChange={(e)=>{ setAppName(e.target.value) }}
              variant="standard"
              error={appNameError}
              helperText={(appNameError) ? "Must be 1 to 32 characters" : null}
            />
            <div className="popup-spacer-x2" />
            {formMarkup}
            <div className="popup-spacer-x4" />
            <Button variant="contained" onClick={()=>{

              // Validate the name.
              if(appName.length < 1 || appName.length > 32){
                setAppNameError(true);
                return;
              }else{
                setAppNameError(false);
              }

              // Validate public.
              if(recaptchaPublic.length < 1){
                setRecaptchaPublicError(true);
                return;
              }else{
                setRecaptchaPublicError(false);
              }

              // Validate private.
              if(recaptchaPrivate.length < 1){
                setRecaptchaPrivateError(true);
                return;
              }else{
                setRecaptchaPrivateError(false);
              }

              if(typeOfApplication === "simple_recaptcha"){
                const tmp = {
                  "Id": editAppId,
                  "Name": appName,
                  "StartTime": 1,
                  "EndTime": 1,
                  "RecaptchaPrivate": recaptchaPrivate,
                  "RecaptchaPublic": recaptchaPublic
                }
  
                handleUpdateApplication(typeOfApplication, tmp)
              }
              
              if(typeOfApplication === "simple_hcaptcha"){
                const tmp = {
                  "Id": editAppId,
                  "Name": appName,
                  "StartTime": 1,
                  "EndTime": 1,
                  "HCaptchaPrivate": recaptchaPrivate,
                  "HCaptchaPublic": recaptchaPublic
                }
  
                handleUpdateApplication(typeOfApplication, tmp)
              }

             }}>Update</Button>
        </FormControl>
        </Box>
      </Modal>
    </div>
  );
}