/*
  # App
  The base application entry point.
*/
import React            from "react";
import { connect }      from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ethers } from "ethers";

import { apiApplicationsGet, apiCombinedLoad }        from "./api/applications";
import { apiApiKeysGet }                              from "./api/apiKeys";
import { apiLogin, apiValidateLogin, apiLoadAccount } from "./api/login";
import { apiHomepageGetStats }                        from "./api/homepage";

import { setApplications } from "./redux/actions/application";
import { setQuestions }    from "./redux/actions/questions";
import { setApiKeys }      from "./redux/actions/apiKeys";
import { setAccount }      from "./redux/actions/account";
import { setLogin, setLoginChecked, setLoginNoAccount } from "./redux/actions/login";

import BetaBanner  from "./components/BetaBanner";
import Header      from "./components/Header";
import Application from "./containers/Application";
import Dashboard   from "./containers/Dashboard";
import Homepage    from "./containers/Homepage";
import Footer      from "./components/Footer";
import Terms       from "./containers/Terms";
import Privacy     from "./containers/Privacy";

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import CreateAccount from "./Popup/CreateAccount";

import './styles/App.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// https://mui.com/about/
class App extends React.Component {

  state = {
    popupOpen: false,
    popupType: "info",
    message: "",

    served: 0,
    solves: 0,
    projects: 0,
  };

  setPopup = (popupType, message) => {
    this.setState({
      popupOpen: true,
      popupType: popupType,
      message: message,
    })
  }

  loadCombined = () => {
    let alias = this;

    apiCombinedLoad(this.props.token).then((arg)=>{
      if(arg.success === false){
        return; // TODO.
      }

      alias.props.setApplications(arg.result.Applications)
      alias.props.setQuestions(arg.result.Questions)
      alias.props.setApiKeys(arg.result.ApiKeys)
    })
  }

  loadApplications = () => {
    let alias = this;

    apiApplicationsGet(this.props.token).then((arg)=>{
      if(arg.success === false){
        return; // TODO.
      }

      console.log("saving", arg.result)
      alias.props.setApplications(arg.result)
    })
  }

  loadApiKeys = () => {
    let alias = this;

    apiApiKeysGet(this.props.token).then((arg)=>{
      if(arg.success === false){
        return; // TODO.
      }

      console.log("saving", arg.result)
      alias.props.setApplications(arg.result)
    })
  }

  loadStats = () => {

    let alias = this;

    apiHomepageGetStats().then((arg)=>{
      if(arg.Success === false){
        return;
      }

      alias.setState({
        served:   arg.result.Served,
        solves:   arg.result.Solved,
        projects: arg.result.ProjectsCreated,
      });
    })
  }

  handleLogout = async () => {
    this.props.setLogin({
      "loading": false,
      "token": "",
      "refresh": "",
      "wallet": "",
      "age": 0,
    });

    this.setPopup("success", "Successfully Logged Out");

    window.location = "/";
  }

  handleLoginRequest = async () => {

    let alias = this;

    try{

      let loginRequestPayload = {
        "login": "digital_samurai",
        "timestamp": Math.floor(Date.now() / 1000),
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()

      const toSign = btoa(JSON.stringify(loginRequestPayload));
      const walletAddress = await signer.getAddress();
      const signature = await signer.signMessage(toSign);

      const generatedLoginRequest = `${walletAddress};${toSign};${signature}`;

      // Set the login to loading so the user can't do it again.
      alias.props.setLogin({
        "loading": true,
        "token": "",
        "refresh": "",
        "wallet": "",
        "age": 0,
      });

      // Turn the sign into a jwt.
      apiLogin(generatedLoginRequest, "").then((arg)=>{
        if(arg.success === false){
          alias.props.setLogin({
            "loading": false,
            "token": "",
            "refresh": "",
            "wallet": "",
            "age": 0,
          });
          this.setPopup("error", "Failed To Login");
          return
        }

        apiLoadAccount("LfUdhjt3A6tWQF3tkbmFP2KxzTJSrhDB").then((arg)=> {
          alias.props.setLoginNoAccount(arg.result === "no_account");

          alias.props.setLogin({
            "loading": false,
            "token": "LfUdhjt3A6tWQF3tkbmFP2KxzTJSrhDB", // <--- debugging arg.result.token,
            "refresh": arg.result.refresh,
            "wallet": walletAddress,
            "age": Math.floor(Date.now() / 1000),
          });

          this.setPopup("success", "Successfully Logged In");
        });
      });
    }catch(e){
      this.setPopup("info", "Login Canceled");
    }
  }

  loadDashboardMetrics = () => {
    // TODO.
  }

  dataReloading = () => {

    let alias = this;

    if(this.props.token === ""){
      setTimeout(this.dataReloading, 1000);
      return
    }

    // Load the account information.
    apiLoadAccount(this.props.token).then((arg)=>{
      if(!arg.success){
        return
      }

      alias.props.setAccount({
        id: arg.result.id,
        used: arg.result.Used,
        credits: arg.result.Credits,
      });
    });


    setTimeout(this.dataReloading, 15000);
  }

  componentDidMount() {

    // Load the metrics.
    this.loadStats();

    let alias = this;

    // Validate the login token to make sure it's still valid.
    apiValidateLogin(this.props.token).then((arg) => {
      if(arg.result === false){
        this.props.setLogin({
          "loading": false,
          "token": "",
          "refresh": "",
          "wallet": "",
          "age": 0,
        });

        this.setPopup("error", "Log In Expired");
        return;
      }

      apiLoadAccount(alias.props.token).then((arg)=> {
        alias.props.setLoginNoAccount(arg.result === "no_account");
        alias.props.setLoginChecked(true);
      })
    })

    this.dataReloading();
  }

  render() {

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({
        popupOpen: false
      });
    };

    return (
      <Router>
        <div className="app-container">
          <BetaBanner />
          <Header
            login={this.handleLoginRequest}
            logout={this.handleLogout}
          />
            <Switch>
              <Route path="/dashboard">
                <Dashboard
                  loadCombined={this.loadCombined}
                  loadApplications={this.loadApplications}
                  loadDashboardMetrics={this.loadDashboardMetrics}
                  setPopup={this.setPopup}
                />
              </Route>
              <Route path="/application/:id">
                <Application />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/">
                <Homepage
                  served={this.state.served}
                  solves={this.state.solves}
                  projects={this.state.projects}
                  login={this.handleLoginRequest}
                />
              </Route>
            </Switch>
          <Footer />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={this.state.popupOpen} autoHideDuration={3500} onClose={handleClose}>
              <Alert onClose={handleClose} severity={this.state.popupType} sx={{ width: '100%' }}>
                {this.state.message}
              </Alert>
            </Snackbar>
          </Stack>
          <CreateAccount
            handleLogout={this.handleLogout}
            noAccount={this.props.noAccount}
            token={this.props.token}
            success={()=>{ this.props.setLoginNoAccount(false) }}
          />
        </div>
      </Router>
    )
  };
}


const myStateToProps = (state) => {
  return {
    "token": state.loginReducer.token,
    "noAccount": state.loginReducer.noAccount,
  };
};

export default connect(myStateToProps, { setApplications, setLogin, setLoginChecked, setLoginNoAccount, setQuestions, setApiKeys, setAccount })(App);

