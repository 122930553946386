import React                                    from 'react';
import { connect }                              from "react-redux";
// import { message, Form, Input, Select, Button, Divider } from 'antd';

class Application extends React.Component {

  state = {};

  render() {
    return (
      <div>Application</div>
    )
  }
}

const myStateToProps = (state) => {
  return {};
};

export default connect(myStateToProps, { })(Application);