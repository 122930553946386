import React       from 'react';
import { connect } from "react-redux";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import ReCAPTCHA from "react-google-recaptcha";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


function onChange(value) {
  console.log("Captcha value:", value);
}


class Demo extends React.Component {

  state = {
    "captchaResult": "",
  };

  /*
  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              adjectiveaa
                            </Typography>
                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                              NFT Minting example (<Link href="#">Link to Ropsten contract</Link>)
                            </Typography>
  */
  render() {
    return (
        <div className="hp-demo-container">
            <div className="content">
                <div className="header">
                    Try it out
                </div>
                <div className="left">
                    <Card variant="outlined" sx={{ minWidth: 275 }}>
                        <CardContent>
                          <div className="hp-demo-captcha-container">
                            <Typography variant="h5" component="div">
                              Mint your very own Slug!
                            </Typography>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">Slug Color</FormLabel>
                              <RadioGroup
                              row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="red"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel value="red" control={<Radio />} label="Red" />
                                <FormControlLabel value="yellow" control={<Radio />} label="Yellow" />
                                <FormControlLabel value="green" control={<Radio />} label="Green" />
                              </RadioGroup>
                            </FormControl>
                            <div className="captcha">
                              <FormLabel id="demo-radio-buttons-group-label">Solve Challenge</FormLabel>
                              <ReCAPTCHA
                                sitekey="6LdqhEoUAAAAAPjXXK-SwNVdTKQibJ00C5205MDS"
                                onChange={onChange}
                              />
                            </div>
                            <div className="submit">
                              <Button variant="contained" disabled={(this.state.captchaResult === "") ? true : false}>Mint A Slug</Button>
                            </div>
                          </div>
                        </CardContent>
                        <div className="hp-demo-contract-link">
                          <CardActions>
                              <Button size="small">Link to Ropsten contract</Button>
                          </CardActions>
                        </div>
                    </Card>
                </div>
                <div className="right">
                    <Card variant="outlined" sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Word of the Day
                            </Typography>
                            <Typography variant="h5" component="div">
                            be{bull}nev{bull}o{bull}lent
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            adjective
                            </Typography>
                            <Typography variant="body2">
                            well meaning and kindly.
                            <br />
                            {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
  };
};

export default connect(myStateToProps, { })(Demo);