// # reducers
// combines all reducers for the redux store

// import dependencies
import { combineReducers } from "redux";

// import reducers
import sourceReducer      from "./source";
import loginReducer       from "./login";
import applicationReducer from "./applications";
import questionsReducer   from "./questions";
import apiKeysReducer     from "./apiKeys";
import accountReducer     from "./account";
import chartReducer       from "./charts";


// set constants
const ENTRY_REDUCER = combineReducers({
    sourceReducer,
    loginReducer,
    applicationReducer,
    questionsReducer,
    apiKeysReducer,
    accountReducer,
    chartReducer,
});

// export the reducer
export default ENTRY_REDUCER;