import React from 'react';

export default class BetaPriceBanner extends React.Component {

    state = {};

    // https://emoji.gg/assets/emoji/8276_Cool_Finger_Guns.png

    render() {
        return (
            <div className="price-beta-banner">
                <div className="positional-child">
                    <div className="child">
                        <div className="icon">
                            <img src="https://cdn3.emoji.gg/emojis/2891-bitcoin.gif" />
                        </div>
                        <div className="text">
                            We are currently in <b>Beta</b>, so currently all transactions are <b>free</b>!
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};