import React       from 'react';
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import HeroArt from './hero-art.jpg'


const BootstrapButton = styled(Button)({
  fontWeight: "bold",
  letterSpacing: 0.4,
  textTransform: "none",
});

class Feature extends React.Component {

  state = {};

  render() {

    let buttonMarkup = (
      <div className="button">
        <Link to="/dashboard">
          <BootstrapButton variant="contained" size="large" endIcon={<ArrowForwardIosIcon />}>Go To Dashboard</BootstrapButton>
        </Link>
      </div>
    )

    if(this.props.token === ""){
      buttonMarkup = (
        <div className="button">
          <BootstrapButton variant="contained" size="large" onClick={this.props.login} endIcon={<ArrowForwardIosIcon />}>Get Started</BootstrapButton>
        </div>
      );
    }


    return (
      <div className="hp-hero-container">
        <div className="art">
          <img src={HeroArt} alt="" />
        </div>
        <div className="text">
          <div className="header">
            The bot protection <b>service</b> you always wanted
          </div>
          <div className="sub-text">
            Digital Samurai is a simple to use service that helps block the malicious users. We offer protection for all contracts no matter if they are big or small.
          </div>
          <div className="get-started">
            {buttonMarkup}
          </div>
        </div>
      </div>
    );
  }
}

const myStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    wallet: state.loginReducer.wallet,
    loading: state.loginReducer.loading,
  };
};

export default connect(myStateToProps, { })(Feature);