
export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function zeroPad(arg){
    if(arg < 10){
      return `0${arg}`;
    }else{
      return arg;
    }
}

export function unixTimeToPretty(arg){
    const dateObj = new Date(arg * 1000);

    const d = dateObj.getDay();
    const m = monthNames[dateObj.getMonth()];
    // const y = dateObj.getFullYear();
    const hh = dateObj.getHours() % 12;
    const mm = zeroPad(dateObj.getMinutes());
    const ss = zeroPad(dateObj.getSeconds());
    const amPm = (dateObj.getHours() >= 12) ? "PM" : "AM";

    return `${m} ${d}, ${hh}:${mm}:${ss} ${amPm}`;
}

export function booleanToPretty(arg){
    return arg ? "True" : "False";
}

export function beautifyNumber(arg){
    if(arg === undefined){
        return "";
    }
    return arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
