import React       from 'react';
import { connect } from "react-redux";

import Hero from "../../components/Hero";
import Demo from "../../components/Demo";
import Features from "../../components/Features";
import RoadMap from "../../components/RoadMap";
import Pricing from "../../components/Pricing";
import CallToAction from "../../components/CallToAction";
import BetaPriceBanner from "../../components/BetaPriceBanner";

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


class Homepage extends React.Component {

  state = {};

  render() {

    console.log("this.props.served", this.props.served)

    return (
      <div>
        <Hero login={this.props.login} />
        <div className="hp-goal-container">
          <div className="content">
            <div className="left">
              <div className="header">
                Our ultimate goal
              </div>
              <div className="sub-text">
              We want to put an end to the bots that are abusing the blockchain networks to earn a quick profit. Our team have been very frustrated with it being impossible to mint any large NFT collections because of bots. Instead of laying down and just taking it we decided to be part of the solution. Our goal is to protect every important transaction on the network from NFT minting to simple contract interaction. The future is blockchain, help us build a better tomorrow by throwing a wrench in the gears of the bot makers
              </div>
            </div>
            <div className="right">
              <div className="stats">
                <div className="item">
                  <div className="amount">
                    {(this.props.served === undefined || this.props.served === 0) ? "?" : nFormatter(this.props.served, 2)}
                  </div>
                  <div className="label">
                    Challenges Served
                  </div>
                </div>
                <div className="item">
                  <div className="amount">
                    {(this.props.solves === undefined || this.props.solves === 0) ? "?" : nFormatter(this.props.solves, 2)}
                  </div>
                  <div className="label">
                    Transactions protected
                  </div>
                </div>
                <div className="item">
                  <div className="amount">
                    {(this.props.projects === undefined || this.props.projects === 0) ? "?" : nFormatter(this.props.projects, 2)}
                  </div>
                  <div className="label">
                    Projects Created
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Features />
        <RoadMap />
        <Demo />
        <BetaPriceBanner />
        <Pricing />
        <CallToAction login={this.props.login} />
      </div>
    )
  }
}

const myStateToProps = (state) => {
  return {};
};

export default connect(myStateToProps, { })(Homepage);