// # application
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "totalChallenges": 0,
    "totalSolved": 0,

    "lastChange": 0,
    "list": [],
};

/**
 * # chartReducer
 */
const chartReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_CHART:
            state.lastChange = Math.random();
            state.list = payload;

            // Sum up the totals.
            let totalChallenges = 0;
            let totalSolved     = 0;

            for(let i = 0; i < payload.length; i += 1){
                totalChallenges += payload[i].Challenges;
                totalSolved     += payload[i].Solved;
            }

            // Set the totals in place.
            state.totalChallenges = totalChallenges;
            state.totalSolved     = totalSolved;

            return {
                ...state
            };


        default:
            return state;
    };
};

export default chartReducer;