import React from 'react';

let terms = [
  {
    "title": "Overview",
    "list": [
      "Please read the following Terms of Service, and understand they are a legally binding document. We encourage you to review the contents carefully in their entirety and to seek legal counsel if you have any concerns. Please note that your use of our WEBSITE constitutes your acceptance of these Terms of Service and your agreement to be bound by them."
    ]
  },
  {
    "title": "Definitions",
    "list": [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Habitant morbi tristique senectus et netus et malesuada fames. Ornare suspendisse sed nisi lacus sed viverra. Feugiat in fermentum posuere urna nec. Sed euismod nisi porta lorem. Id venenatis a condimentum vitae sapien pellentesque. Suscipit tellus mauris a diam. Tellus integer feugiat scelerisque varius morbi enim nunc. Ipsum suspendisse ultrices gravida dictum fusce ut placerat. Magnis dis parturient montes nascetur ridiculus mus mauris vitae. Faucibus a pellentesque sit amet porttitor eget dolor. Quam pellentesque nec nam aliquam sem et tortor consequat id. Elit ut aliquam purus sit amet luctus venenatis lectus magna."
    ]
  },
  {
    "title": "Overview",
    "list": [
      "Tempor commodo ullamcorper a lacus vestibulum sed arcu. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Placerat vestibulum lectus mauris ultrices eros in. Nisl purus in mollis nunc sed id semper. Orci dapibus ultrices in iaculis nunc sed augue. Mauris in aliquam sem fringilla ut morbi tincidunt. Ullamcorper dignissim cras tincidunt lobortis feugiat. Placerat duis ultricies lacus sed turpis tincidunt id aliquet. Ut consequat semper viverra nam libero justo laoreet sit amet. In arcu cursus euismod quis viverra nibh cras pulvinar. Tincidunt vitae semper quis lectus nulla. Cursus turpis massa tincidunt dui ut. Fermentum posuere urna nec tincidunt.",
      "Two Tempor commodo ullamcorper a lacus vestibulum sed arcu. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Placerat vestibulum lectus mauris ultrices eros in. Nisl purus in mollis nunc sed id semper. Orci dapibus ultrices in iaculis nunc sed augue. Mauris in aliquam sem fringilla ut morbi tincidunt. Ullamcorper dignissim cras tincidunt lobortis feugiat. Placerat duis ultricies lacus sed turpis tincidunt id aliquet. Ut consequat semper viverra nam libero justo laoreet sit amet. In arcu cursus euismod quis viverra nibh cras pulvinar. Tincidunt vitae semper quis lectus nulla. Cursus turpis massa tincidunt dui ut. Fermentum posuere urna nec tincidunt."
    ]
  },
  {
    "title": "Use of this site",
    "list": [
      "Tincidunt praesent semper feugiat nibh. Diam vel quam elementum pulvinar etiam non quam. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Nunc id cursus metus aliquam eleifend mi in. Accumsan sit amet nulla facilisi morbi tempus. Elit scelerisque mauris pellentesque pulvinar pellentesque. Ante metus dictum at tempor commodo ullamcorper. Ullamcorper a lacus vestibulum sed arcu non. Ultrices in iaculis nunc sed augue lacus viverra. Neque aliquam vestibulum morbi blandit cursus risus at. Metus vulputate eu scelerisque felis imperdiet. Suspendisse potenti nullam ac tortor vitae purus faucibus. Gravida in fermentum et sollicitudin ac. Nisi quis eleifend quam adipiscing. Ipsum faucibus vitae aliquet nec ullamcorper sit. Fermentum posuere urna nec tincidunt praesent. Auctor urna nunc id cursus metus aliquam eleifend mi in. Tempus imperdiet nulla malesuada pellentesque elit. Enim ut tellus elementum sagittis vitae et leo duis."
    ]
  },
  {
    "title": "Ownership",
    "list": [
      "Venenatis cras sed felis eget velit aliquet. Laoreet sit amet cursus sit. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Sed blandit libero volutpat sed cras. Faucibus in ornare quam viverra. Sem integer vitae justo eget magna fermentum. Egestas tellus rutrum tellus pellentesque eu tincidunt. Aliquet lectus proin nibh nisl condimentum id venenatis a. Velit scelerisque in dictum non consectetur a erat. Urna cursus eget nunc scelerisque viverra mauris. Fringilla urna porttitor rhoncus dolor purus non enim. Fringilla ut morbi tincidunt augue interdum velit. Et pharetra pharetra massa massa ultricies mi quis hendrerit. Tincidunt lobortis feugiat vivamus at augue. Enim ut sem viverra aliquet eget sit amet."
    ]
  },
  {
    "title": "Accounts",
    "list": [
      "Id diam maecenas ultricies mi eget mauris. Nisl vel pretium lectus quam id. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Diam ut venenatis tellus in metus. Nullam vehicula ipsum a arcu cursus. Dolor sit amet consectetur adipiscing elit ut aliquam purus. Nibh praesent tristique magna sit amet. Varius vel pharetra vel turpis nunc eget lorem dolor. Amet cursus sit amet dictum sit amet justo donec. Morbi leo urna molestie at."
    ]
  }
]

export default class Terms extends React.Component {

  state = {};

  render() {
    let body = [];

    // Build the body.
    for(let i = 0; i < terms.length; i += 1){
      let builtBody = [];

      // Loop over the body and build the text.
      for(let g = 0; g < terms[i].list.length; g += 1){
        builtBody.push(
          <p>{terms[i].list}</p>
        )
      }

      // Create the element item.
      body.push(
        <div className="item">
          <div className="title">
            {terms[i].title}
          </div>
          <div className="full-body">
            {builtBody}
          </div>
        </div>
      )
    }

    /*
    <div className="page-sub-text">
      Please read the following Terms of Service, and understand they are a legally binding document. We encourage you to review the contents carefully in their entirety and to seek legal counsel if you have any concerns. Please note that your use of our WEBSITE constitutes your acceptance of these Terms of Service and your agreement to be bound by them.
    </div>
    */

    return (
      <div className="legal-container">
        <div className="page-title">
          Terms of Service
        </div>
        <div className="legal-full-container">
          {body}
        </div>
        <div className="divider" />
        <div className="last-update">
          Last update: January 12th, 2022.
        </div>
      </div>
    )
  }
}
