// # plangroup
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "list": []
};

/**
 * # sourceReducer
 */
const sourceReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_SOURCE:

            state.list = payload;

            return {
                ...state,
                //...payload
            };

        case types.DELETE_SOURCE:

            let reduced = [];

            for(let i = 0; i < state.list.length; i += 1){
                if(state.list[i].id !== payload){
                    reduced.push(state.list[i])
                }
            }

            state.list = reduced;

            return {
                ...state
            };
        default:
            return state;
    };
};

export default sourceReducer;