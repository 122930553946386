// # Plans
// handles the notification actions for the redux store
// import dependencies
import types from "../constants/types";

/**
 * # setPlans
 * todo
 * 
 * @param {Object} payload
 * 
 * @returns null
 */
export const setLogin = payload => ({
    "type": types.SET_LOGIN,
    payload
});

export const setLoginChecked = payload => ({
    "type": types.SET_LOGIN_CHECKED,
    payload
});

export const setLoginNoAccount = payload => ({
    "type": types.SET_LOGIN_NO_ACCOUNT,
    payload
});
