import React       from 'react';
import { connect } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';

import Divider from '@mui/material/Divider';

const secToDay = 86400

const renderCustomAxisTickX = ({ x, y, payload }) => {
    return (
        <Text x={x - 30} y={y + 20} style={{ fontSize: 12 }}>
            {payload.value}
        </Text>
    );
  };

const renderCustomAxisTickY = ({ x, y, payload }) => {
    return (
        <Text x={x - 35} y={y + 4} style={{ fontSize: 12, textAlign: "center" }}>
            {payload.value}
        </Text>
    );
  };

function separator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}
  

class UsageChartContainer extends React.Component {

  state = {};

  // stroke="#132F4C"

  generateChartData = () => {
    let nowTS = Math.floor(Date.now() / 1000);
    let list = [];

    for(let i = 0; i < 7; i += 1){
      let currentTS = nowTS - (i * secToDay);

      let today = new Date(currentTS * 1000);
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      list.push({
        name: `${mm}/${dd}/${yyyy}`,
        solved: 0,
        served: 0,
        ts: currentTS,
      });
    }

    // Inject the totals.
    for(let i = 0; i < this.props.chartList.length; i += 1){
      for(let y = 1; y < list.length; y += 1){
        if(list[y].ts <= this.props.chartList[i].ts){
          list[y - 1].solved += this.props.chartList[i].Solved
          list[y - 1].served += this.props.chartList[i].Challenges
          break
        }
      }
    }

    // Reverse the list.
    list = list.reverse();
    
    return list;
  }

  render() {

    let generatedData = this.generateChartData();

    return (
        <div className="dashboard-chart-container">
            <div className="chart-container">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={generatedData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name"  tick={renderCustomAxisTickX} />
                    <YAxis tick={renderCustomAxisTickY} />
                    <Tooltip />
                    <Area type="monotone" dataKey="solved" stackId="0" stroke="#82ca9d" fill="#82ca9d" />
                    <Area type="monotone" dataKey="served" stackId="1" stroke="#007FFF" fill="#007FFF" />         
                    </AreaChart>
                </ResponsiveContainer>
            </div>
            <div className="totals">
                <div className="title">
                    Total Usage
                </div>
                <div className="sub-text">
                    This is the total for the last 7 days.
                </div>
                <Divider />
                <div className="item">
                    <div className="title-name">
                        Total Challenges
                    </div>
                    <div className="total">
                        {separator(this.props.chartChallenges)}
                    </div>
                </div>
                <Divider />
                <div className="item">
                    <div className="title-name">
                        Solved Challenges
                    </div>
                    <div className="total">
                        {separator(this.props.chartSolved)}
                    </div>
                </div>
                <Divider />
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
    chartChallenges: state.chartReducer.totalChallenges,
    chartSolved:     state.chartReducer.totalSolved,
    chartLastChange: state.chartReducer.lastChange,
    chartList:       state.chartReducer.list,
  };
};

export default connect(myStateToProps, { })(UsageChartContainer);