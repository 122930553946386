import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';

// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';

const stepsGeneral = [
  {
    label: 'Protection for Ethereum',
    description: ``,
  },
  {
    label: 'Release beta',
    description: `Release for free beta and fix bugs and get suggestions for future improvements. Join our Discord to suggest ideas!`,
  },
  {
    label: 'Better metric tracking',
    description: ``,
  },
  {
    label: 'Website improvements',
    description: ``,
  },
  {
    label: 'Implement pricing plans',
    description: ``,
  },
  {
    label: 'Protection for Solana',
    description: ``,
  },
];

const stepsAntiBot = [
    {
        label: 'Recaptcha support',
        description: 'Intergration for Recaptcha',
    },
    {
        label: 'HCaptcha support',
        description: 'Intergration for HCaptcha',
    },
    {
        label: 'Wallet support',
        description: 'Validate that the wallet is not a burner created to bypass restrictions'
    },
    {
        label: 'Discord server member requirement',
        description: ''
    },
    {
        label: 'Twitter following requirement',
        description: ''
    },
    {
        label: 'Custom validation support',
        description: ''
    },
]


export default function RoadMap() {

  const generateMap = (steps, activeStep) => {
    return (
        <div className="roadmap-content">
            <Stepper activeStep={activeStep} orientation="vertical">
            {
                steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                        </StepContent>
                    </Step>
                ))
            }
        </Stepper>
        </div>
      )
  }

  return (
    <div className="hp-roadmap-container">
    <div className="content">
        <div className="header">
            Road Map
        </div>
        <div>
            Digital Samurai is currently in Beta so features will be added as our developers finish them.
        </div>
        <div className="left">
            <div className="sub-header">
                General Features
            </div>
            {generateMap(stepsGeneral, 1)}
        </div>
        <div className="right">
            <div className="sub-header">
                Bot Deterrent Features
            </div>
            {generateMap(stepsAntiBot, 2)}
        </div>
    </div>
</div>



  );
}
