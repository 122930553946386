import React       from 'react';
import { connect } from "react-redux";
import Divider from '@mui/material/Divider';

import CheckIcon from '@mui/icons-material/Check';

class Pricing extends React.Component {

  state = {};

  // https://mui.com/
  // https://www.hcaptcha.com/
  // https://dribbble.com/shots/14652261-Bondlayer-Pricing

  /*
    Exceptionally affordable
    simple Transparent pricing
  */

  render() {
    return (
        <div className="hp-pricing-container">
            <div className="content">
                <div className="hp-force-color-column" />
                <div className="header">
                    Pricing*
                </div>
                <div className="per-tx">
                    <div className="spacer">Features</div>
                    <div className="item">
                        <div className="little-text">
                            Trying it out
                        </div>
                        <div className="big-text">
                            Free
                        </div>
                        <div className="sub-text">
                            1000 Transactions
                        </div>
                    </div>
                    <div className="item">
                        <div className="little-text">
                            Small Website
                        </div>
                        <div className="big-text">
                            {0.25 / 10000} <b>Eth</b>
                        </div>
                        <div className="sub-text">
                            per transaction
                        </div>
                        <div className="">
                            
                        </div>
                    </div>
                    <div className="item">
                        <div className="little-text">
                            Big Website
                        </div>
                        <div className="big-text">
                            {0.1 / 10000} <b>Eth</b>
                        </div>
                        <div className="sub-text">
                            per transaction
                        </div>
                    </div>
                    <div className="item">
                        <div className="little-text">
                            Even larger
                        </div>
                        <div className="big-text">
                            Contact Us
                        </div>
                        <div className="sub-text">
                            per transaction
                        </div>
                    </div>
                </div>
                <div className="split-up">
                    <Divider />
                </div>
                <div className="feature">
                    <div className="label">
                        Only pay for successful solves
                    </div>
                    <div className="item a">
                        <CheckIcon />
                    </div>
                    <div className="item b">
                        <CheckIcon />
                    </div>
                    <div className="item c">
                        <CheckIcon />
                    </div>
                    <div className="item d">
                        <CheckIcon />
                    </div>
                </div>
                <div className="feature-split-up">
                    <Divider />
                </div>
                <div className="feature">
                    <div className="label">
                        Works on multiple contracts
                    </div>
                    <div className="item a">
                        <CheckIcon />
                    </div>
                    <div className="item b">
                        <CheckIcon />
                    </div>
                    <div className="item c">
                        <CheckIcon />
                    </div>
                    <div className="item d">
                        <CheckIcon />
                    </div>
                </div>
                <div className="feature-split-up">
                    <Divider />
                </div>
                <div className="feature">
                    <div className="label">
                        Credits never expire☨
                    </div>
                    <div className="item a">
                        -
                    </div>
                    <div className="item b">
                        <CheckIcon />
                    </div>
                    <div className="item c">
                        <CheckIcon />
                    </div>
                    <div className="item d">
                        <CheckIcon />
                    </div>
                </div>
                <div className="feature-split-up">
                    <Divider />
                </div>
                <div className="feature">
                    <div className="label">
                        Minimum order amount
                    </div>
                    <div className="item a">
                        -
                    </div>
                    <div className="item b">
                        <b>0.01</b> Eth
                    </div>
                    <div className="item c">
                        <b>1</b> Eth
                    </div>
                    <div className="item d">
                        -
                    </div>
                </div>
                <div className="feature-split-up">
                    <Divider />
                </div>
                <div className="feature">
                    <div className="label">
                        Usable for future features
                    </div>
                    <div className="item a">
                        <CheckIcon />
                    </div>
                    <div className="item b">
                        <CheckIcon />
                    </div>
                    <div className="item c">
                        <CheckIcon />
                    </div>
                    <div className="item d">
                        <CheckIcon />
                    </div>
                </div>
                <div className="feature-split-up">
                    <Divider />
                </div>
                <div className="beta-price-note">
                    * Due to the fact that Digital Samurai is in Beta, prices / plans are subject to change. Please join our Discord for up to date announcements
                </div>
                <div className="beta-price-note">
                    ☨ See terms and conditions for more information on how credits function
                </div>
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
  };
};

export default connect(myStateToProps, { })(Pricing);