import React       from 'react';
import { connect } from "react-redux";
import { styled }     from '@mui/material/styles';
// import AssessmentIcon from '@mui/icons-material/Assessment';
import Button from '@mui/material/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

const docUrl = "https://www.notion.so/digitalsamurai/Metering-Billing-d6b58744d47644409766193e97a38492";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));



/*
import Box            from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography     from '@mui/material/Typography';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

<LinearProgressWithLabel className="credit-progress" value={50} />
*/

function separator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}


class DashboardMeterContainer extends React.Component {

  state = {};

  render() {

    /*
        <div className="icon">
            <AssessmentIcon color="primary" />
        </div>
    */
   
    // Force the credit to be 10,000,000
    let accountCredits = 10000000; // this.props.accountCredits
    let accountUsed = this.props.accountUsed

    // Calculate the percent.
    let percent = (accountUsed / accountCredits) * 100;

    if(percent > 100){
      percent = 100;
    }

    return (
        <div className="dashboard-meter-container">
            <div className="title">
                Account Limits (Credits)
            </div>
            <div className="sub-text">
                Each validated transaction costs 1 credit.
            </div>
            <Divider />
            <div className="meter">
                <div className="amount">
                    <div className="soft-title">
                        Credits spent / Total credits
                    </div>
                    <div className="amount-used">
                        <div className="used">
                            {separator(this.props.accountUsed)}
                        </div>
                        <div className="middle">
                            /
                        </div>
                        <div className="total">
                            10,000,000
                        </div>
                    </div>
                </div>
            </div>
            <div className="progress-bar">
                <BorderLinearProgress variant="determinate" value={percent} />
            </div>
            <Divider />
            <div className="add-credits">
                <div>
                    <Button variant="contained" disabled>Add Credits</Button>
                </div>
                <div>
                    <Button variant="outlined" onClick={()=>{ window.open(docUrl, "_blank")}}>Documentation</Button>
                </div>
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
    /*
      Account Related.
    */
    accountId:      state.accountReducer.id,
    accountUsed:    state.accountReducer.used,
    accountCredits: state.accountReducer.credits
  };
};

export default connect(myStateToProps, { })(DashboardMeterContainer);