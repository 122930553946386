// # application
// handles the reducers for the profiles store
// import dependencies
import types from "../constants/types";

// set constants
let initialState = {
    "lastChange": 0,
    "loaded": false,
    "list": [],
};

/**
 * # applicationReducer
 */
const applicationReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_APPLICATIONS:
            state.loaded = true;
            state.list = payload;

            return {
                ...state
            };

        case types.SET_APPLICATIONS_ENABLED:
            const id = payload.id;
            const enabled = payload.enabled;

            let tmp = state.list;

            for(let i = 0; i < tmp.length; i += 1){
                if(tmp[i].id === id){
                    tmp[i].Enabled = enabled;
                    break;
                }
            }

            state.list = tmp;
            state.lastChange = Math.random();

            return {
                ...state
            }

        default:
            return state;
    };
};

export default applicationReducer;