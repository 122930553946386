import { genericRequest } from "../generic";
import {
    urlApplication,
    urlApplicationEnabled,
    urlAppCreateSimpleRecap,
    urlAppCreateSimpleHCap,
    urlCombinedReload
} from "../config";

/**
 *  # apiApplicationsGet
 *  xxxx
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiApplicationsGet = async (authorization) => {
    return genericRequest(urlApplication, "get", {}, authorization);
};


export const apiApplicationsDelete = async (id, authorization) => {
    return genericRequest(urlApplication, "delete", { "id": id }, authorization);
};

export const apiApplicationsSetEnabled = async (id, enabled, authorization) => {
    return genericRequest(urlApplicationEnabled, "post", { "Id": id, "Enabled": enabled }, authorization);
};

export const apiApplicationsCreateSimpleRecap = async (payload, authorization) => {
    return genericRequest(urlAppCreateSimpleRecap, "post", payload, authorization);
};

export const apiApplicationsUpdateSimpleRecap = async (payload, authorization) => {
    return genericRequest(urlAppCreateSimpleRecap, "put", payload, authorization);
};

export const apiApplicationsCreateSimpleHCap = async (payload, authorization) => {
    return genericRequest(urlAppCreateSimpleHCap, "post", payload, authorization);
};

export const apiApplicationsUpdateSimpleHCap = async (payload, authorization) => {
    return genericRequest(urlAppCreateSimpleHCap, "put", payload, authorization);
};

export const apiCombinedLoad = async (authorization) => {
    return genericRequest(urlCombinedReload, "get", {}, authorization);
};