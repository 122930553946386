import { genericRequest } from "../generic";
import { urlLoadCharts } from "../config";

/**
 *  # apiApplicationsGet
 *  xxxx
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiChartsGet = async (authorization) => {
    return genericRequest(urlLoadCharts, "get", {}, authorization);
};
