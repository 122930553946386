
import React       from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';

class CallToAction extends React.Component {

  state = {};

  render() {

    let buttonMarkup = (
      <div className="centered">
        <Button variant="contained" size="large" onClick={this.props.login}>Sign Up</Button>
      </div>
    )

    if(this.props.token !== ""){
      buttonMarkup = (
        <div className="centered">
          <Link to="/dashboard">
          <Button
            variant="contained"
            size="large"
          >Go To Dashboard</Button>
          </Link>
        </div>
      )
    }

    return (
        <div className="hp-call-to-action-container">
            <div className="content">
                <div className="header">
                  Want to see for yourself? Try for free
                </div>
                {buttonMarkup}
            </div>
        </div>
    )
  }
}

const myStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    wallet: state.loginReducer.wallet,
    loading: state.loginReducer.loading,
  };
};

export default connect(myStateToProps, { })(CallToAction);