import React                                                 from 'react';
import { connect }                                           from "react-redux";
import { beautifyNumber } from "../../helpers";
import { Redirect }                                    from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

// https://react-chartjs-2.js.org/examples/vertical-bar-chart
// https://mui.com/material-ui/react-switch/

import Confirmation from "../../components/Confirmation";

import {
  apiApplicationsDelete,
  apiApplicationsSetEnabled,
  apiApplicationsCreateSimpleRecap,
  apiApplicationsUpdateSimpleRecap,
  apiApplicationsUpdateSimpleHCap,
  apiApplicationsCreateSimpleHCap,
} from "../../api/applications";

import { apiChartsGet } from "../../api/charts";

import { apiApiKeysRegen } from "../../api/apiKeys";
import { setApplicationsEnabled } from "../../redux/actions/application";
import { setCharts } from "../../redux/actions/charts";

import DashboardMeterContainer from '../../components/Meter';
import UsageChartContainer from '../../components/UsageChart';


// Mui.
import Table          from '@mui/material/Table';
import TableBody      from '@mui/material/TableBody';
import TableCell      from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead      from '@mui/material/TableHead';
import TableRow       from '@mui/material/TableRow';
import Paper          from '@mui/material/Paper';
import Box            from '@mui/material/Box';
import Card           from '@mui/material/Card';
import Button         from '@mui/material/Button';

// Popups.
import CreateApplication from '../../Popup/CreateApplication';
import EditApplication   from '../../Popup/EditApplication';
import CreateAccount     from '../../Popup/CreateAccount';

import Loading from '../../components/Loading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


/*
  Total credits.
    - Total credits used in the last 7 days
    - Last
    - Usage graph?

  List application keys
*/
class Dashboard extends React.Component {

  state = {
    loadingText: "Validating Login",

    dataLoading: false,

    deleteDialogOpen: false,
    deleteId: "",

    // Create application
    applicationModal: false,

    // Edit application
    editApplicationModal: false,
    editAppId:      "",
    editAppType:    "",
    editAppName:    "",
    editAppPrivate: "",
    editAppPublic:  "",
  };

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
      deleteId: "",
    });
  };

  handleDialogDelete = () => {
    // Delete the record.
    apiApplicationsDelete(this.state.deleteId, this.props.token);

    // Clear the state.
    this.setState({
      deleteDialogOpen: false,
      deleteId: "",
    });

    // Reload the applications.
    this.props.loadApplications();

    // Say our delete was successfull.
    this.props.setPopup("success", "Successfully Deleted");
  };

  handleEnableDisable = (id, enabled) => {

    // Say our delete was successfull.
    apiApplicationsSetEnabled(id, !enabled, this.props.token).then((arg) => {

      // Reload the applications.
      //this.props.loadApplications();

      // Update just internally.
      this.props.setApplicationsEnabled({
        id: id,
        enabled: !enabled,
      });

      // Display the popup.
      this.props.setPopup("success", (!enabled) ? "Successfully Enabled" : "Successfully Disabled");
    });
  };

  openDelete = (id) => {
    this.setState({
      deleteDialogOpen: true,
      deleteId: id,
    });
  };

  waitForLoginValidation = () => {

    // If we are waiting for our login check.
    if(this.props.loginChecked === false){
      return;
    }

    // Prevent double loading the data.
    if(this.state.dataLoading){
      return;
    }
    this.setState({
      dataLoading: true,
      loadingText: "Loading Data"
    });

    // Load the dashboard metrics.
    this.props.loadDashboardMetrics();

    // Load the applications.
    this.props.loadCombined();
  };

  handleUpdateApplication = (typeOfApplication, payload) => {
    if(typeOfApplication === "simple_recaptcha"){
      apiApplicationsUpdateSimpleRecap(payload, this.props.token).then((result)=>{
        if(result.success){
          this.props.setPopup("success", "Successfully Updated");
          this.setState({ editApplicationModal: false });
          this.props.loadCombined();
        }else{
          this.props.setPopup("error", "Error Updating Application");
        }      
      })
    }
    if(typeOfApplication === "simple_hcaptcha"){
      apiApplicationsUpdateSimpleHCap(payload, this.props.token).then((result)=>{
        if(result.success){
          this.props.setPopup("success", "Successfully Updated");
          this.setState({ editApplicationModal: false });
          this.props.loadCombined();
        }else{
          this.props.setPopup("error", "Error Updating Application");
        }      
      })
    }
  };

  handleCreateApplication = (typeOfApplication, payload) => {
    if(typeOfApplication === "simple_recaptcha"){
      apiApplicationsCreateSimpleRecap(payload, this.props.token).then((result)=>{
        if(result.success){
          this.props.setPopup("success", "Successfully Created");
          this.setState({ applicationModal: false });
          this.props.loadCombined();
        }else{
          this.props.setPopup("error", "Error Creating Application");
        }      
      })
    }

    if(typeOfApplication === "simple_hcaptcha"){
      apiApplicationsCreateSimpleHCap(payload, this.props.token).then((result)=>{
        if(result.success){
          this.props.setPopup("success", "Successfully Created");
          this.setState({ applicationModal: false });
          this.props.loadCombined();
        }else{
          this.props.setPopup("error", "Error Creating Application");
        }      
      })
    }
  };

  handleEdit = (appId, name) => {
    let found = null;

    // Find the question form for it.
    for(let i = 0; i < this.props.questions.length; i += 1){
      if(this.props.questions[i].ApplicationId === appId){
        found = this.props.questions[i];
      }
    }

    // If we didn't find the question, reject.
    if(found === null){
      return
    }

    // Determine the type of application it is.
    if(found["Questions"][0]["type"] === "recap"){
      // Find the question form for it.
      this.setState({
        editApplicationModal: true,

        editAppId:      appId,
        editAppName:    name,
        editAppType:    "simple_recaptcha",
        editAppPublic:  found["Questions"][0]["data"]["public"],
        editAppPrivate: found["SecretQuestions"][0]["data"]["secret"], 
      });
    }
    // Determine the type of application it is.
    if(found["Questions"][0]["type"] === "hcap"){
      // Find the question form for it.
      this.setState({
        editApplicationModal: true,

        editAppId:      appId,
        editAppName:    name,
        editAppType:    "simple_hcaptcha",
        editAppPublic:  found["Questions"][0]["data"]["public"],
        editAppPrivate: found["SecretQuestions"][0]["data"]["secret"], 
      });
    }
  };

  handleRegenApikey = (appId) => {
    apiApiKeysRegen(appId, this.props.token).then((result)=>{
      if(result.success){
        this.props.setPopup("success", "Successfully Regenerated");
        this.props.loadCombined();
      }else{
        this.props.setPopup("error", "Error Regenerating");
      }      
    })
  };

  handleAfterCopy = () => {
    this.props.setPopup("success", "Api key copied to clipboard");
  };

  componentDidMount(){
    this.loadCharts();
  };
  /*
    loadCharts
    Load the charts used for the
  */
  loadCharts = () => {

    let alias = this;

    apiChartsGet(this.props.token).then((arg)=>{

      // If we weren't successful then return nothing.
      if(arg.success === false){
        return;
      }

      // Set the chart in place.
      alias.props.setCharts(arg.result);
    });

  };

  render() {

    // If the user has no login token, redirect them to the homepage.
    if(this.props.token === ""){
      return (<Redirect to={{ pathname: `/` }} />)
    }

    // Re-check out validation.
    this.waitForLoginValidation();

    // Don't render the page until all data is loaded.
    if(!this.props.applicationsLoaded || !this.props.dashboardLoaded){
      return (
        <div className="dashboard-container">
          <div className="waiting">
            <Loading text={this.state.loadingText} />
          </div>
        </div>
      )
    }

    let builtRows = [];

    if(this.props.applicationList !== undefined){

      // Map the apikeys to the application id.
      let apiKeyMap = {};

      for(let i = 0; i < this.props.apiKeys.length; i += 1){
        apiKeyMap[this.props.apiKeys[i].ApplicationId] = this.props.apiKeys[i].id
      }

      for(let i = 0; i < this.props.applicationList.length; i += 1){
        const row = this.props.applicationList[i];

        const apiKeyValue = (apiKeyMap[row.id] !== undefined) ? apiKeyMap[row.id] : "N/A";

        builtRows.push((
          <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" component="th" scope="row">
              <div className="dbt-link">
                {row.Name}
              </div>
            </TableCell>
            <TableCell align="center">{beautifyNumber(row.SolveCount)}</TableCell>
            <TableCell align="center" className="dashboard-apikey-color">
              <div className="api-key">
                <CopyToClipboard text={apiKeyValue} onCopy={this.handleAfterCopy}>
                  <span>{apiKeyValue}</span>
                </CopyToClipboard>
              </div>
              <div className="api-icons">
                <IconButton aria-label="secondary">
                  <RefreshIcon
                    fontSize="small"
                    color="disabled"
                    onClick={()=>{ this.handleRegenApikey(row.id) }}
                  />
                </IconButton>
              </div>
            </TableCell>
            <TableCell align="center">
              <div className="dashboard-table-controls">
                <div className="item">
                  <Button
                    variant="contained"
                    color={(row.Enabled) ? "error" : "primary"}
                    size="small"
                    onClick={()=>{ this.handleEnableDisable(row.id, row.Enabled)}}
                    >{(row.Enabled) ? "Disable" : "Enable"}</Button>
                </div>
                <div className="item">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => { this.handleEdit(row.id, row.Name) }}
                    >Edit</Button>
                </div>
                <div className="item">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={()=>{
                      this.openDelete(row.id);
                    }}
                  >Delete</Button>
                </div>
              </div>
            </TableCell>
          </TableRow>
        ));
      }
    }

    // Inject a placeholder if it's empty.
    if(builtRows.length < 1){
      builtRows = (
        <div className="dbt-empty-table">
          <Box sx={{ height: '100%' }}>
            TODO
          </Box>
        </div>
      );
    }

    let editModal = null;

    if(this.state.editApplicationModal){
      editModal = (<EditApplication
        open={this.state.editApplicationModal}
        handleUpdateApplication={this.handleUpdateApplication}
        handleOpen={()=>{ this.setState({ editApplicationModal: true }) }}
        handleClose={()=>{ this.setState({ editApplicationModal: false }) }}
        editAppId={this.state.editAppId}
        editAppName={this.state.editAppName}
        editAppType={this.state.editAppType}
        editAppPrivate={this.state.editAppPrivate}
        editAppPublic={this.state.editAppPublic}
      />)
    }

    /*
      <TableCell align="center"><b>Start Time</b></TableCell>
      <TableCell align="center"><b>End Time</b></TableCell>
    */

      // <Bar options={options} data={data} />

    return (
      <div key={this.props.lastChange} className="dashboard-container">
        <div className="section-header">
          Dashboard
        </div>
        <div className="overview">
          <div className="a1">
            <Box sx={{ Width: 875, height: 400 }}>
              <Card variant="outlined" sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, height: 282, width: 700 }}>
                <UsageChartContainer />
              </Card>
            </Box>
          </div>
          <div className="b1">
            <Box sx={{ Width: 275, height: 400 }}>
              <Card variant="outlined" sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 4, height: 250, width: 325 }}>
                <DashboardMeterContainer />
              </Card>
            </Box>
          </div>
        </div>
        <div className="section-header">
          Applications
        </div>
        <div className="section-sub">
          <div className="text">
            The list of applications that are using the protection. We recommend one application per project to provide better protection.
          </div>
          <div className="create">
            <Button
              variant="contained"
              onClick={()=>{ this.setState({ applicationModal: true }) }}
              >New Application</Button>
          </div>
        </div>
        <div className="application-list">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"><b>Application Name</b></TableCell>
                  <TableCell align="center"><b>Solve Count</b></TableCell>
                  <TableCell align="center"><b>API Key</b></TableCell>
                  <TableCell align="center"><b>Controls</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {builtRows}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Confirmation
          open={this.state.deleteDialogOpen}
          handleClose={this.handleDialogClose}
          handleDelete={this.handleDialogDelete}
        />
        <CreateApplication
          open={this.state.applicationModal}
          handleCreateApplication={this.handleCreateApplication}
          handleOpen={()=>{ this.setState({ applicationModal: true }) }}
          handleClose={()=>{ this.setState({ applicationModal: false }) }}
        />
        {editModal}
        <CreateAccount />
      </div>
    )
  }
}

const myStateToProps = (state) => {
  return {

    /*
      Login Related.
    */
    loginChecked: state.loginReducer.loginChecked,
    token: state.loginReducer.token,

    /*
      Dashboard Related.
    */
    dashboardLoaded: true,
    
    /*
      Questions Related.
    */
    questions: state.questionsReducer.list,

    /*
      ApiKey Related.
    */
    apiKeys: state.apiKeysReducer.list,

    /*
      Application Related.
    */
    applicationsLoaded: state.applicationReducer.loaded,
    applicationList: state.applicationReducer.list,
    lastChange: state.applicationReducer.lastChange,

    /*
      Account Related.
    */
    accountId:      state.accountReducer.id,
    accountUsed:    state.accountReducer.used,
    accountCredits: state.accountReducer.credits,

    /*
      Chart related fields.
    */
    chartChallenges: state.chartReducer.totalChallenges,
    chartSolved:     state.chartReducer.totalSolved,
    chartLastChange: state.chartReducer.lastChange,
    chartList:       state.chartReducer.list,
  };
};

export default connect(myStateToProps, { setApplicationsEnabled, setCharts })(Dashboard);